import { render, staticRenderFns } from "./DefaultLottoWorkspace.vue?vue&type=template&id=6a1f0a4f&scoped=true&"
import script from "./DefaultLottoWorkspace.vue?vue&type=script&lang=js&"
export * from "./DefaultLottoWorkspace.vue?vue&type=script&lang=js&"
import style0 from "./DefaultLottoWorkspace.vue?vue&type=style&index=0&id=6a1f0a4f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a1f0a4f",
  null
  
)

export default component.exports